import { archiveMonthRolloverData } from "../user/actions";
import {
  ARCHIVE_MONTH_ROLLOVER_DATA_FAIL,
  ARCHIVE_MONTH_ROLLOVER_DATA_REQUEST,
} from "../user/actionType";
import axios from "./utils/axios";

const fetchRolloverData = async (dispatch, getState) => {
  try {
    dispatch({ type: ARCHIVE_MONTH_ROLLOVER_DATA_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`api/common/rollover_month/`, config);
    dispatch(archiveMonthRolloverData(data));
  } catch (error) {
    dispatch({
      type: ARCHIVE_MONTH_ROLLOVER_DATA_FAIL,
      payload:
        error.response && error.response.data.details
          ? error.response.data.details
          : error.message,
    });
  }
};
export default fetchRolloverData;
