import { createVendor } from "../vendor/actions";
import {
  CREATE_VENDOR_FAIL,
  CREATE_VENDOR_REQUEST,
} from "../vendor/actionType";
import axios from "./utils/axios";

const fetchVendorCreate = (vendor) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_VENDOR_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post("api/vendor/", vendor, config);
      dispatch(createVendor(data));
    } catch (error) {
      dispatch({
        type: CREATE_VENDOR_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchVendorCreate;
