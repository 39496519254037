import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import fetchUserLogin from "../../redux/thunk/fetchLogin";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
export default function AdminHome() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const login = useSelector((state) => state.login);
  const { loading, isError, userInfo, error } = login || {};
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(fetchUserLogin(email, password));
  };

  useEffect(() => {
    if (userInfo) {
      navigate("/product");
    }
  }, [navigate, userInfo]);
  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && <Loading />}
        {isError && <Error message={error} />}
      </div>
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <Form onSubmit={submitHandler} style={{ margin: "60px 0" }}>
            <Form.Group controlId="email">
              <Form.Label> Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br />
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <br />
            <Button type="submit" variant="primary">
              Sign In
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
