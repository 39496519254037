import {
  MONTHLY_PULLET_INVENTORY_SUCCESS,
  PULLET_INVENTORY_CREATE_SUCCESS,
  PULLET_INVENTORY_DELETE_SUCCESS,
  PULLET_INVENTORY_DETAILS_BASE_ON_MONTH_SUCCESS,
  PULLET_INVENTORY_LIST_SUCCESS,
  PULLET_INVENTORY_UPDATE_SUCCESS,
} from "./actionType";

export const createPullet = (data) => {
  return {
    type: PULLET_INVENTORY_CREATE_SUCCESS,
    payload: data,
  };
};

export const createMonthlyPullet = (data) => {
  return {
    type: MONTHLY_PULLET_INVENTORY_SUCCESS,
    payload: data,
  };
};

export const updatePullet = (data) => {
  return {
    type: PULLET_INVENTORY_UPDATE_SUCCESS,
    payload: data,
  };
};

export const pulletList = (data) => {
  return {
    type: PULLET_INVENTORY_LIST_SUCCESS,
    payload: data,
  };
};

export const deletePullet = (data) => {
  return {
    type: PULLET_INVENTORY_DELETE_SUCCESS,
    payload: data,
  };
};

export const monthlyPulletDetails = (data) => {
  return {
    type: PULLET_INVENTORY_DETAILS_BASE_ON_MONTH_SUCCESS,
    payload: data,
  };
};
