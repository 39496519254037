import { undoArchiveMonthRolloverData } from "../user/actions";
import {
  UNDO_ARCHIVE_MONTH_ROLLOVER_DATA_FAIL,
  UNDO_ARCHIVE_MONTH_ROLLOVER_DATA_REQUEST,
} from "../user/actionType";
import axios from "./utils/axios";

const fetchUndoRolloverData = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UNDO_ARCHIVE_MONTH_ROLLOVER_DATA_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `api/common/month/undo_rollback/${id}/`,
        config
      );
      dispatch(undoArchiveMonthRolloverData(data));
    } catch (error) {
      dispatch({
        type: UNDO_ARCHIVE_MONTH_ROLLOVER_DATA_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchUndoRolloverData;
