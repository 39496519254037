import { updateVendor } from "../vendor/actions";
import {
  UPDATE_VENDOR_FAIL,
  UPDATE_VENDOR_REQUEST,
} from "../vendor/actionType";
import axios from "./utils/axios";

const fetchVendorUpdate = (vendorId, formData) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_VENDOR_REQUEST });

      const {
        login: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // Don't set Content-Type explicitly
        },
      };

      const { data } = await axios.put(
        `api/vendor/${vendorId}/`,
        formData,
        config
      );

      dispatch(updateVendor(data));
    } catch (error) {
      dispatch({
        type: UPDATE_VENDOR_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};

export default fetchVendorUpdate;
