import { monthArchive } from "../user/actions";
import { ARCHIVE_MONTH_FAIL, ARCHIVE_MONTH_REQUEST } from "../user/actionType";
import axios from "./utils/axios";

const fetchArchiveMonth = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ARCHIVE_MONTH_REQUEST });
      const {
        login: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `api/common/month/archive/${id}/`,
        {},
        config
      );
      dispatch(monthArchive(data));
    } catch (error) {
      dispatch({
        type: ARCHIVE_MONTH_FAIL,
        payload:
          error.response && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};
export default fetchArchiveMonth;
