export const CREATE_SHIPMENT_DATE_REQUEST =
  "order/CREATE_SHIPMENT_DATE_REQUEST";
export const CREATE_SHIPMENT_DATE_SUCCESS =
  "order/CREATE_SHIPMENT_DATE_SUCCESS";
export const CREATE_SHIPMENT_DATE_FAIL = "order/CREATE_SHIPMENT_DATE_FAIL";

export const UPDATE_SHIPMENT_DATE_REQUEST =
  "order/UPDATE_SHIPMENT_DATE_REQUEST";
export const UPDATE_SHIPMENT_DATE_SUCCESS =
  "order/UPDATE_SHIPMENT_DATE_SUCCESS";
export const UPDATE_SHIPMENT_DATE_FAIL = "order/UPDATE_SHIPMENT_DATE_FAIL";

export const DELETE_SHIPMENT_DATE_REQUEST =
  "order/DELETE_SHIPMENT_DATE_REQUEST";
export const DELETE_SHIPMENT_DATE_SUCCESS =
  "order/DELETE_SHIPMENT_DATE_SUCCESS";
export const DELETE_SHIPMENT_DATE_FAIL = "order/DELETE_SHIPMENT_DATE_FAIL";

export const SHIPMENT_DATE_REPORT_REQUEST =
  "order/SHIPMENT_DATE_REPORT_REQUEST";
export const SHIPMENT_DATE_REPORT_SUCCESS =
  "order/SHIPMENT_DATE_REPORT_SUCCESS";
export const SHIPMENT_DATE_REPORT_FAIL = "order/SHIPMENT_DATE_REPORT_FAIL";

export const SHIPMENT_DATE_LIST_REQUEST = "order/SHIPMENT_DATE_LIST_REQUEST";
export const SHIPMENT_DATE_LIST_SUCCESS = "order/SHIPMENT_DATE_LIST_SUCCESS";
export const SHIPMENT_DATE_LIST_FAIL = "order/SHIPMENT_DATE_LIST_FAIL";

export const SHIPMENT_COVER_PAGE_REPORT_REQUEST =
  "order/SHIPMENT_COVER_PAGE_REPORT_REQUEST";
export const SHIPMENT_COVER_PAGE_REPORT_SUCCESS =
  "order/SHIPMENT_COVER_PAGE_REPORT_SUCCESS";
export const SHIPMENT_COVER_PAGE_REPORT_FAIL =
  "order/SHIPMENT_COVER_PAGE_REPORT_FAIL";

export const SHIPMENT_AVAIBILITY_CHANGE_REQUEST =
  "order/SHIPMENT_AVAIBILITY_CHANGE_REQUEST";
export const SHIPMENT_AVAIBILITY_CHANGE_SUCCESS =
  "order/SHIPMENT_AVAIBILITY_CHANGE_SUCCESS";
export const SHIPMENT_AVAIBILITY_CHANGE_FAIL =
  "order/SHIPMENT_AVAIBILITY_CHANGE_FAIL";

export const ORDER_CREATE_REQUEST = "order/ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "order/ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "order/ORDER_CREATE_FAIL";

export const ORDER_UPDATE_REQUEST = "order/ORDER_UPDATE_REQUEST";
export const ORDER_UPDATE_SUCCESS = "order/ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_FAIL = "order/ORDER_UPDATE_FAIL";

export const ORDER_VIEW_REQUEST = "order/ORDER_VIEW_REQUEST";
export const ORDER_VIEW_SUCCESS = "order/ORDER_VIEW_SUCCESS";
export const ORDER_VIEW_FAIL = "order/ORDER_VIEW_FAIL";

export const ORDER_DELETE_REQUEST = "order/ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "order/ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAIL = "order/ORDER_DELETE_FAIL";

export const ORDER_LIST_REQUEST = "order/ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "order/ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "order/ORDER_LIST_FAIL";

export const ORDER_STATUS_CHANGE_REQUEST = "order/ORDER_STATUS_CHANGE_REQUEST";
export const ORDER_STATUS_CHANGE_SUCCESS = "order/ORDER_STATUS_CHANGE_SUCCESS";
export const ORDER_STATUS_CHANGE_FAIL = "order/ORDER_STATUS_CHANGE_FAIL";

export const ORDER_REPORT_REQUEST = "order/ORDER_REPORT_REQUEST";
export const ORDER_REPORT_SUCCESS = "order/ORDER_REPORT_SUCCESS";
export const ORDER_REPORT_FAIL = "order/ORDER_REPORT_FAIL";

export const ORDER_CANCEL_REQUEST = "order/ORDER_CANCEL_REQUEST";
export const ORDER_CANCEL_SUCCESS = "order/ORDER_CANCEL_SUCCESS";
export const ORDER_CANCEL_FAIL = "order/ORDER_CANCEL_FAIL";

export const ORDER_SHIPMENT_AVAILABLE_DATE_REQUEST =
  "order/ORDER_SHIPMENT_AVAILABLE_DATE_REQUEST";
export const ORDER_SHIPMENT_AVAILABLE_DATE_SUCCESS =
  "order/ORDER_SHIPMENT_AVAILABLE_DATE_SUCCESS";
export const ORDER_SHIPMENT_AVAILABLE_DATE_FAIL =
  "order/ORDER_SHIPMENT_AVAILABLE_DATE_FAIL";

export const ORDER_SUMMARY_REQUEST = "order/ORDER_SUMMARY_REQUEST";
export const ORDER_SUMMARY_SUCCESS = "order/ORDER_SUMMARY_SUCCESS";
export const ORDER_SUMMARY_FAIL = "order/ORDER_SUMMARY_FAIL";
