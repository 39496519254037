import {
  CREATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_SUCCESS,
  VENDOR_DELETE_SUCCESS,
  VENDOR_DETAILS_SUCCESS,
  VENDOR_LIST_SUCCESS,
} from "./actionType";

export const createVendor = (data) => {
  return {
    type: CREATE_VENDOR_SUCCESS,
    payload: data,
  };
};

export const updateVendor = (data) => {
  return {
    type: UPDATE_VENDOR_SUCCESS,
    payload: data,
  };
};

export const vendorDetails = (data) => {
  return {
    type: VENDOR_DETAILS_SUCCESS,
    payload: data,
  };
};

export const vendorList = (data) => {
  return {
    type: VENDOR_LIST_SUCCESS,
    payload: data,
  };
};

export const deleteVendor = (data) => {
  return {
    type: VENDOR_DELETE_SUCCESS,
    payload: data,
  };
};
