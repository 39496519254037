import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SummaryCard from "./SummaryCard";

const OrderSummary = React.forwardRef((props, ref) => {
  const { summary } = props || {};

  return (
    <Container
      ref={ref}
      className="order-summary-container"
      style={{
        padding: "20px",
        border: "1px solid #ccc",
        width: "8in",
        fontFamily: "serif",
        pageBreakInside: "avoid", // Prevent breaking inside the container
      }}
    >
      <Row className="order-summary">
        <Col xs={12} md={12}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Order Summary
          </p>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Ship Date: {summary?.shipment_date}
          </p>
        </Col>
        <hr />
        {summary?.vendors?.length > 0 &&
          summary?.vendors?.map((vendor, index) => (
            <div
              key={index}
              className="order-summary-vendor"
              style={{
                pageBreakInside: "avoid", // Ensure vendors are grouped together
                marginBottom: "20px",
              }}
            >
              <SummaryCard vendor={vendor || {}} index={index} />
            </div>
          ))}
      </Row>
    </Container>
  );
});

export default OrderSummary;
