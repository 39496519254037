import { archiveMonthList } from "../user/actions";
import {
  ARCHIVE_LIST_MONTH_FAIL,
  ARCHIVE_LIST_MONTH_REQUEST,
} from "../user/actionType";
import axios from "./utils/axios";

const fetchArchiveMonthList = async (dispatch, getState) => {
  try {
    dispatch({ type: ARCHIVE_LIST_MONTH_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`api/common/month/archive/`, config);
    dispatch(archiveMonthList(data));
  } catch (error) {
    dispatch({
      type: ARCHIVE_LIST_MONTH_FAIL,
      payload:
        error.response && error.response.data.details
          ? error.response.data.details
          : error.message,
    });
  }
};
export default fetchArchiveMonthList;
