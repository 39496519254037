import {
  CREATE_SHIPMENT_DATE_FAIL,
  CREATE_SHIPMENT_DATE_REQUEST,
  CREATE_SHIPMENT_DATE_SUCCESS,
  DELETE_SHIPMENT_DATE_FAIL,
  DELETE_SHIPMENT_DATE_REQUEST,
  DELETE_SHIPMENT_DATE_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_REPORT_FAIL,
  ORDER_REPORT_REQUEST,
  ORDER_REPORT_SUCCESS,
  ORDER_SHIPMENT_AVAILABLE_DATE_FAIL,
  ORDER_SHIPMENT_AVAILABLE_DATE_REQUEST,
  ORDER_SHIPMENT_AVAILABLE_DATE_SUCCESS,
  ORDER_STATUS_CHANGE_FAIL,
  ORDER_STATUS_CHANGE_REQUEST,
  ORDER_STATUS_CHANGE_SUCCESS,
  ORDER_SUMMARY_FAIL,
  ORDER_SUMMARY_REQUEST,
  ORDER_SUMMARY_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_VIEW_FAIL,
  ORDER_VIEW_REQUEST,
  ORDER_VIEW_SUCCESS,
  SHIPMENT_AVAIBILITY_CHANGE_FAIL,
  SHIPMENT_AVAIBILITY_CHANGE_REQUEST,
  SHIPMENT_AVAIBILITY_CHANGE_SUCCESS,
  SHIPMENT_COVER_PAGE_REPORT_FAIL,
  SHIPMENT_COVER_PAGE_REPORT_REQUEST,
  SHIPMENT_COVER_PAGE_REPORT_SUCCESS,
  SHIPMENT_DATE_LIST_FAIL,
  SHIPMENT_DATE_LIST_REQUEST,
  SHIPMENT_DATE_LIST_SUCCESS,
  SHIPMENT_DATE_REPORT_FAIL,
  SHIPMENT_DATE_REPORT_REQUEST,
  SHIPMENT_DATE_REPORT_SUCCESS,
  UPDATE_SHIPMENT_DATE_FAIL,
  UPDATE_SHIPMENT_DATE_REQUEST,
  UPDATE_SHIPMENT_DATE_SUCCESS,
} from "./actionType";

export const shipmentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_SHIPMENT_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_SHIPMENT_DATE_SUCCESS:
      return {
        loading: false,
        shipment: action.payload,
        success: true,
        isError: false,
      };
    case CREATE_SHIPMENT_DATE_FAIL:
      return {
        loading: false,
        shipment: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const shipmentUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SHIPMENT_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_SHIPMENT_DATE_SUCCESS:
      return {
        loading: false,
        shipment: action.payload,
        success: true,
        isError: false,
      };
    case UPDATE_SHIPMENT_DATE_FAIL:
      return {
        loading: false,
        shipment: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const shipmentDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SHIPMENT_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SHIPMENT_DATE_SUCCESS:
      return {
        loading: false,
        shipment: action.payload,
        success: true,
        isError: false,
      };
    case DELETE_SHIPMENT_DATE_FAIL:
      return {
        loading: false,
        shipment: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const shipmentDateListReducer = (state = [], action) => {
  switch (action.type) {
    case SHIPMENT_DATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SHIPMENT_DATE_LIST_SUCCESS:
      return {
        loading: false,
        shipments: action.payload,
        success: true,
        isError: false,
      };
    case SHIPMENT_DATE_LIST_FAIL:
      return {
        loading: false,
        shipments: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const shipmentDateReportReducer = (state = [], action) => {
  switch (action.type) {
    case SHIPMENT_DATE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SHIPMENT_DATE_REPORT_SUCCESS:
      return {
        loading: false,
        shipments: action.payload,
        success: true,
        isError: false,
      };
    case SHIPMENT_DATE_REPORT_FAIL:
      return {
        loading: false,
        shipments: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const shipmentAvaibilityReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIPMENT_AVAIBILITY_CHANGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SHIPMENT_AVAIBILITY_CHANGE_SUCCESS:
      return {
        loading: false,
        shipment: action.payload,
        success: true,
        isError: false,
      };
    case SHIPMENT_AVAIBILITY_CHANGE_FAIL:
      return {
        loading: false,
        shipment: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        order: action.payload,
        success: true,
        isError: false,
      };
    case ORDER_CREATE_FAIL:
      return {
        loading: false,
        order: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_UPDATE_SUCCESS:
      return {
        loading: false,
        order: action.payload,
        success: true,
        isError: false,
      };
    case ORDER_UPDATE_FAIL:
      return {
        loading: false,
        order: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_DELETE_SUCCESS:
      return {
        loading: false,
        order: action.payload,
        success: true,
        isError: false,
      };
    case ORDER_DELETE_FAIL:
      return {
        loading: false,
        order: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const orderViewReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_VIEW_SUCCESS:
      return {
        loading: false,
        order: action.payload,
        success: true,
        isError: false,
      };
    case ORDER_VIEW_FAIL:
      return {
        loading: false,
        order: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const orderCancelReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CANCEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_CANCEL_SUCCESS:
      return {
        loading: false,
        order: action.payload,
        success: true,
        isError: false,
      };
    case ORDER_CANCEL_FAIL:
      return {
        loading: false,
        order: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderStatusChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_STATUS_CHANGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_STATUS_CHANGE_SUCCESS:
      return {
        loading: false,
        order: action.payload,
        success: true,
        isError: false,
      };
    case ORDER_STATUS_CHANGE_FAIL:
      return {
        loading: false,
        order: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderListReducer = (state = [], action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
        success: true,
        isError: false,
      };
    case ORDER_LIST_FAIL:
      return {
        loading: false,
        orders: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const orderReportReducer = (state = [], action) => {
  switch (action.type) {
    case ORDER_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_REPORT_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
        success: true,
        isError: false,
      };
    case ORDER_REPORT_FAIL:
      return {
        loading: false,
        orders: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const shipmentReportReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIPMENT_COVER_PAGE_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SHIPMENT_COVER_PAGE_REPORT_SUCCESS:
      return {
        loading: false,
        shipment: action.payload,
        success: true,
        isError: false,
      };
    case SHIPMENT_COVER_PAGE_REPORT_FAIL:
      return {
        loading: false,
        shipment: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderShipmentAvailableDateReducer = (state = [], action) => {
  switch (action.type) {
    case ORDER_SHIPMENT_AVAILABLE_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_SHIPMENT_AVAILABLE_DATE_SUCCESS:
      return {
        loading: false,
        dates: action.payload,
        success: true,
        isError: false,
      };
    case ORDER_SHIPMENT_AVAILABLE_DATE_FAIL:
      return {
        loading: false,
        dates: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderSummaryReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_SUMMARY_SUCCESS:
      return {
        loading: false,
        summary: action.payload,
        success: true,
        isError: false,
      };
    case ORDER_SUMMARY_FAIL:
      return {
        loading: false,
        summary: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
