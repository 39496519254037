import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Image,
  Modal,
  Form,
} from "react-bootstrap";
import viewButton from "../../assets/view.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import fetchVendorCreate from "../../redux/thunk/fetchVendorCreate";
import fetchVendorList from "../../redux/thunk/fetchVendorList";

export default function VendorList() {
  const login = useSelector((state) => state.login);
  const vendorList = useSelector((state) => state.vendorList);
  const vendorCreate = useSelector((state) => state.vendorCreate);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = login || {};
  const { isError, loading, vendors, error } = vendorList || {};
  const { isCreating, createError } = vendorCreate || {};

  const [showModal, setShowModal] = useState(false);
  const [newVendor, setNewVendor] = useState({
    company_name: "",
    phone_number: "",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    logo: null, // Added logo state
  });

  const VendorDetails = (id) => {
    navigate(`/vendor/${id}`);
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchVendorList);
  }, [navigate, userInfo, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVendor((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setNewVendor((prev) => ({
      ...prev,
      logo: e.target.files[0], // Handle logo file
    }));
  };

  const handleCreateVendor = async () => {
    const formData = new FormData();
    formData.append("company_name", newVendor.company_name);
    formData.append("phone_number", newVendor.phone_number);
    formData.append("street_address", newVendor.street_address);
    formData.append("city", newVendor.city);
    formData.append("state", newVendor.state);
    formData.append("zip_code", newVendor.zip_code);
    if (newVendor.logo) {
      formData.append("logo", newVendor.logo); // Append logo to form data
    }

    try {
      await dispatch(fetchVendorCreate(formData)); // Pass formData to the action
      setShowModal(false); // Close the modal
      setNewVendor({
        company_name: "",
        phone_number: "",
        street_address: "",
        city: "",
        state: "",
        zip_code: "",
        logo: null, // Reset logo
      }); // Reset the form
      dispatch(fetchVendorList); // Reload the vendor list
    } catch (error) {
      console.error("Error creating vendor:", error);
      alert("Failed to create vendor. Please try again.");
    }
  };

  const handleCancel = () => {
    setShowModal(false); // Close the modal
    setNewVendor({
      company_name: "",
      phone_number: "",
      street_address: "",
      city: "",
      state: "",
      zip_code: "",
      logo: null, // Reset logo
    }); // Reset the form
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading && <Loading />}
        {isError && <Error message={error} />}
      </div>
      <Row className="justify-content-md-center">
        <Col
          xs={6}
          md={6}
          sm={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <h2>Vendor List </h2>
        </Col>
        <Col
          xs={6}
          md={6}
          sm={12}
          style={{ textAlign: "center", margin: "20px 0px" }}
        >
          <Button variant="info" onClick={() => setShowModal(true)}>
            Create Vendor
          </Button>
        </Col>
      </Row>
      <Table striped bordered hover style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Number</th>
            <th>Address</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {vendors?.length > 0 ? (
            vendors.map((vendor, index) => (
              <tr key={vendor?.id}>
                <td>{index + 1}</td>
                <td>{vendor?.company_name}</td>
                <td>{vendor?.phone_number}</td>
                <td>
                  {vendor?.street_address}, {vendor?.city}, {vendor?.state},{" "}
                  {vendor?.zip_code}
                </td>
                <td style={{ display: "flex", justifyContent: "space-around" }}>
                  <Image
                    src={viewButton}
                    alt="view"
                    style={{
                      width: "25px",
                      height: "25px",
                      padding: "3px",
                      borderRadius: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() => VendorDetails(vendor?.id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Modal for Creating a Vendor */}
      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Create Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                name="company_name"
                value={newVendor.company_name}
                onChange={handleInputChange}
                placeholder="Enter company name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone_number"
                value={newVendor.phone_number}
                onChange={handleInputChange}
                placeholder="Enter phone number"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                type="text"
                name="street_address"
                value={newVendor.street_address}
                onChange={handleInputChange}
                placeholder="Enter street address"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={newVendor.city}
                onChange={handleInputChange}
                placeholder="Enter city"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Control
                type="text"
                name="state"
                value={newVendor.state}
                onChange={handleInputChange}
                placeholder="Enter state"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type="text"
                name="zip_code"
                value={newVendor.zip_code}
                onChange={handleInputChange}
                placeholder="Enter zip code"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Logo</Form.Label>
              <Form.Control
                type="file"
                name="logo"
                onChange={handleFileChange} // Handle file change
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateVendor}>
            {isCreating ? "Creating..." : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
