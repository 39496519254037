import React from "react";
import { Table } from "react-bootstrap";

export default function SummaryCard({ vendor, index }) {
  const defaultVendorName = "Valley Hatchery";

  return (
    <div style={{ margin: "10px 0" }} key={index}>
      <p style={{ fontSize: "16px", fontWeight: "bold", display: "flex" }}>
        Vendor: {vendor?.company_name || defaultVendorName}
      </p>
      <Table striped bordered hover style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Customer Name</th>
            <th>Total Pullets</th>
            <th>Breakdown</th>
          </tr>
        </thead>
        <tbody style={{ cursor: "pointer" }}>
          {(vendor?.orders || []).map((order, index) => (
            <tr key={index}>
              <td>{order?.order_number || "N/A"}</td>
              <td>{order?.customer_name || "Unknown"}</td>
              <td>{order?.total_pullet || 0}</td>
              <td>
                {(order?.breakdown || []).map((item, index) => (
                  <span key={index}>
                    {`${item?.count || 0} ${item?.product_name || "Unknown"}`}
                    {index < order.breakdown.length - 1 ? ", " : ""}
                  </span>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <hr />
    </div>
  );
}
