import {
  CREATE_VENDOR_FAIL,
  CREATE_VENDOR_REQUEST,
  CREATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_FAIL,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  VENDOR_DELETE_FAIL,
  VENDOR_DELETE_REQUEST,
  VENDOR_DELETE_SUCCESS,
  VENDOR_DETAILS_FAIL,
  VENDOR_DETAILS_REQUEST,
  VENDOR_DETAILS_SUCCESS,
  VENDOR_LIST_FAIL,
  VENDOR_LIST_REQUEST,
  VENDOR_LIST_SUCCESS,
} from "./actionType";

export const vendorCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_VENDOR_SUCCESS:
      return {
        loading: false,
        vendor: action.payload,
        success: true,
        isError: false,
      };
    case CREATE_VENDOR_FAIL:
      return {
        loading: false,
        vendor: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const vendorUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_VENDOR_SUCCESS:
      return {
        loading: false,
        vendor: action.payload,
        success: true,
        isError: false,
      };
    case UPDATE_VENDOR_FAIL:
      return {
        loading: false,
        vendor: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const vendorDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VENDOR_DELETE_SUCCESS:
      return {
        loading: false,
        vendor: action.payload,
        success: true,
        isError: false,
      };
    case VENDOR_DELETE_FAIL:
      return {
        loading: false,
        vendor: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const vendorViewReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VENDOR_DETAILS_SUCCESS:
      return {
        loading: false,
        vendor: action.payload,
        success: true,
        isError: false,
      };
    case VENDOR_DETAILS_FAIL:
      return {
        loading: false,
        vendor: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const vendorListReducer = (state = { vendors: [] }, action) => {
  switch (action.type) {
    case VENDOR_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VENDOR_LIST_SUCCESS:
      return {
        loading: false,
        vendors: action.payload,
        success: true,
        isError: false,
      };
    case VENDOR_LIST_FAIL:
      return {
        loading: false,
        vendors: [],
        isError: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
