import {
  CREATE_SHIPMENT_DATE_SUCCESS,
  DELETE_SHIPMENT_DATE_SUCCESS,
  ORDER_CANCEL_SUCCESS,
  ORDER_CREATE_SUCCESS,
  ORDER_DELETE_SUCCESS,
  ORDER_LIST_SUCCESS,
  ORDER_REPORT_SUCCESS,
  ORDER_SHIPMENT_AVAILABLE_DATE_SUCCESS,
  ORDER_STATUS_CHANGE_SUCCESS,
  ORDER_SUMMARY_SUCCESS,
  ORDER_UPDATE_SUCCESS,
  ORDER_VIEW_SUCCESS,
  SHIPMENT_AVAIBILITY_CHANGE_SUCCESS,
  SHIPMENT_COVER_PAGE_REPORT_SUCCESS,
  SHIPMENT_DATE_LIST_SUCCESS,
  SHIPMENT_DATE_REPORT_SUCCESS,
  UPDATE_SHIPMENT_DATE_SUCCESS,
} from "./actionType";

export const createShipmentDate = (data) => {
  return {
    type: CREATE_SHIPMENT_DATE_SUCCESS,
    payload: data,
  };
};

export const updateShipmentDate = (data) => {
  return {
    type: UPDATE_SHIPMENT_DATE_SUCCESS,
    payload: data,
  };
};

export const deleteShipmentDate = (data) => {
  return {
    type: DELETE_SHIPMENT_DATE_SUCCESS,
    payload: data,
  };
};

export const shipmentDateList = (data) => {
  return {
    type: SHIPMENT_DATE_LIST_SUCCESS,
    payload: data,
  };
};

export const shipmentDateReport = (data) => {
  return {
    type: SHIPMENT_DATE_REPORT_SUCCESS,
    payload: data,
  };
};

export const shipmentAvaibility = (data) => {
  return {
    type: SHIPMENT_AVAIBILITY_CHANGE_SUCCESS,
    payload: data,
  };
};

// Order
export const createOrder = (data) => {
  return {
    type: ORDER_CREATE_SUCCESS,
    payload: data,
  };
};

export const updateOrder = (data) => {
  return {
    type: ORDER_UPDATE_SUCCESS,
    payload: data,
  };
};

export const deleteOrder = (data) => {
  return {
    type: ORDER_DELETE_SUCCESS,
    payload: data,
  };
};

export const viewOrder = (data) => {
  return {
    type: ORDER_VIEW_SUCCESS,
    payload: data,
  };
};

export const cancelOrder = (data) => {
  return {
    type: ORDER_CANCEL_SUCCESS,
    payload: data,
  };
};

export const orderStatusChange = (data) => {
  return {
    type: ORDER_STATUS_CHANGE_SUCCESS,
    payload: data,
  };
};

export const orderList = (data) => {
  return {
    type: ORDER_LIST_SUCCESS,
    payload: data,
  };
};

export const reportOrder = (data) => {
  return {
    type: ORDER_REPORT_SUCCESS,
    payload: data,
  };
};

export const reportCoverShipment = (data) => {
  return {
    type: SHIPMENT_COVER_PAGE_REPORT_SUCCESS,
    payload: data,
  };
};

export const orderShipmentAvaialbleDateList = (data) => {
  return {
    type: ORDER_SHIPMENT_AVAILABLE_DATE_SUCCESS,
    payload: data,
  };
};

export const orderSummary = (data) => {
  return {
    type: ORDER_SUMMARY_SUCCESS,
    payload: data,
  };
};
