import React, { useEffect } from "react";
import { Col, Container, Image, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import backbutton from "../../assets/leftdown.png";
import Error from "../ui/Error";
import Loading from "../ui/Loading";
import fetchMonthlyPulletDetails from "../../redux/thunk/fetchMontlyPulletDetails";

export default function MonthPulletDetails() {
  const { monthId } = useParams();
  const { month } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const monthlyPulletDetails = useSelector(
    (state) => state.monthlyPulletDetails
  );
  const backArchiveListHandler = (e) => {
    e.preventDefault();
    navigate(`/archive-month/`);
  };
  const { userInfo } = login || {};
  const { pullet_list, loading, error, isError } = monthlyPulletDetails || {};
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchMonthlyPulletDetails(monthId));
  }, [navigate, userInfo, dispatch, monthId]);
  return (
    <Container>
      {loading && <Loading />}
      {isError && <Error message={error} />}

      <Row className="d-flex justify-content-between align-items-center">
        <Col
          xs={12}
          md={6}
          style={{
            margin: "20px 0",
          }}
        >
          <h3>Pullet Details : {month}</h3>
        </Col>

        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-end"
          style={{
            margin: "20px 0",
          }}
        >
          <Image
            src={backbutton}
            alt="archive"
            title="Archive List"
            style={{
              width: "35px",
              height: "35px",
              cursor: "pointer",
            }}
            onClick={backArchiveListHandler}
          />
        </Col>
      </Row>
      <Table
        striped
        bordered
        hover
        style={{ textAlign: "center", cursor: "pointer" }}
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Product Name</th>
            <th>Gross Available</th>
            <th>Reserve</th>
            <th>Net Available</th>
          </tr>
        </thead>
        {pullet_list?.length > 0 ? (
          <tbody>
            {Array.isArray(pullet_list) &&
              pullet_list?.map((pullet, index) => (
                <tr key={pullet.id}>
                  <td>{index + 1}</td>
                  <td>{pullet.product}</td>
                  <td>{pullet.gross_available}</td>
                  <td>{pullet.researve}</td>
                  <td>{pullet.net_available}</td>
                </tr>
              ))}
          </tbody>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            No Data Found
          </div>
        )}
      </Table>
    </Container>
  );
}
