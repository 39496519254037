import React, { useEffect } from "react";
import { Col, Container, Image, Row, Table } from "react-bootstrap";
import viewButton from "../../assets/view.png";
import undoButton from "../../assets/undo.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import fetchArchiveMonthList from "../../redux/thunk/fetchArchiveMonth";
import Error from "../ui/Error";
import Loading from "../ui/Loading";
import fetchUndoArchiveMonth from "../../redux/thunk/fetchUndoMonthArchive";

export default function ArchiveMonthList() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const listArchiveMonth = useSelector((state) => state.listArchiveMonth);
  const { userInfo } = login || {};
  const { months, loading, isError, error } = listArchiveMonth || {};

  const archiveMonthDetails = (id, month_name, year) => {
    let month = `${month_name} ${year}`;
    navigate(`/month-pullet/${month}/${id}`);
  };
  const undoArchiveMonth = async (id) => {
    try {
      // Dispatch the undo action
      await dispatch(fetchUndoArchiveMonth(id));
      // Fetch the updated list of archived months
      dispatch(fetchArchiveMonthList);
    } catch (error) {
      console.error("Failed to undo archive:", error);
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchArchiveMonthList);
  }, [navigate, userInfo, dispatch]);
  return (
    <Container>
      {loading && <Loading />}
      {isError && <Error message={error} />}
      <Row className="d-flex justify-content-between align-items-center">
        <Col
          xs={12}
          md={12}
          style={{
            margin: "20px 0",
          }}
        >
          <h1>Archive Month List</h1>
        </Col>
      </Row>
      <Table striped bordered hover style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Month Name</th>
            <th>Year</th>
            <th>Action</th>
          </tr>
        </thead>
        {months?.length > 0 ? (
          <tbody>
            {Array.isArray(months) &&
              months?.map((month, index) => (
                <tr key={month.id}>
                  <td>{index + 1}</td>
                  <td>{month.month_name}</td>
                  <td>{month.year}</td>
                  <td
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Image
                      src={viewButton}
                      alt="view"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        archiveMonthDetails(
                          month?.id,
                          month?.month_name,
                          month?.year
                        )
                      }
                    />
                    <Image
                      src={undoButton}
                      alt="undo"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => undoArchiveMonth(month?.id)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            No Data Found
          </div>
        )}
      </Table>
    </Container>
  );
}
