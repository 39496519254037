import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Table,
  Modal,
  ListGroup,
} from "react-bootstrap";
import deleteButton from "../../assets/delete.png";
import archiveButton from "../../assets/box.png";
import rollBackButton from "../../assets/roll_back.png";
import rolloverImg from "../../assets/rollover.png";
import archiveImg from "../../assets/archive.png";
import editButton from "../../assets/edit.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import fetchMonth from "../../redux/thunk/fetchMonth";
import fetchCreateMonth from "../../redux/thunk/fetchCreateMonth";
import fetchUpdateMonth from "../../redux/thunk/fetchUpdateMonth";
import fetchDeleteMonth from "../../redux/thunk/fetchDeleteMonth";
import Success from "../ui/Success";
import fetchArchiveMonth from "../../redux/thunk/fetchMonthArchive";
import fetchRollBack from "../../redux/thunk/fetchRollBack.";

export default function MonthManage() {
  const [monthName, setMonthName] = useState("");
  const [year, setYear] = useState("");
  const [id, setId] = useState("");
  const [shouldReload, setShouldReload] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [selectedMonthId, setSelectedMonthId] = useState(null); // State to track selected month for rollback
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const monthList = useSelector((state) => state.monthList);
  const monthAdd = useSelector((state) => state.monthAdd);
  const monthUpdate = useSelector((state) => state.monthUpdate);
  const monthDelete = useSelector((state) => state.monthDelete);
  const archiveMonth = useSelector((state) => state.archiveMonth);
  const monthRollBack = useSelector((state) => state.monthRollBack);
  const { userInfo } = login || {};
  const { months, loading, isError, error } = monthList || {};
  const {
    loading: archiveLoading,
    isError: archiveIsError,
    error: archiveError,
  } = archiveMonth || {};
  const {
    loading: rollOverLoading,
    isError: rollOverIsError,
    error: rollOverError,
  } = monthRollBack || {};
  const {
    loading: addLoading,
    success,
    error: addError,
    isError: addIsError,
  } = monthAdd || {};
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
    isError: updateIsError,
  } = monthUpdate || {};
  const {
    loading: deleteLoading,
    error: deleteError,
    isError: deleteIsError,
  } = monthDelete || {};

  const createMonthHandler = (e) => {
    e.preventDefault();
    let month_dics = {
      month_name: monthName,
      year: year,
    };
    dispatch(fetchCreateMonth(month_dics)).then(() => {
      setMonthName("");
      setYear("");
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
  };

  const updateMonthHandler = (e) => {
    e.preventDefault();
    let month_dics = {
      month_name: monthName,
      year: year,
    };
    dispatch(fetchUpdateMonth(month_dics, id)).then(() => {
      setShouldReload((prev) => !prev); // Toggle the shouldReload state
    });
    setEditMode(false);
    setMonthName("");
    setYear("");
  };

  const editButtonHandeler = (id, month_name, year) => {
    setMonthName(month_name);
    setYear(year);
    setId(id);
    setEditMode(true);
  };

  const archiveButtonHandeler = (id) => {
    setId(id);
    dispatch(fetchArchiveMonth(id))
      .then(() => {
        setShouldReload((prev) => !prev);
      })
      .catch((error) => {
        console.error("Error archiving month:", error);
      });
  };

  const deleteButtonHandler = (id) => {
    dispatch(fetchDeleteMonth(id)).then(() => {
      setShouldReload((prev) => !prev);
    });
  };

  const errorConditions = [
    { isError: isError, message: error },
    { isError: addIsError, message: addError },
    { isError: rollOverIsError, message: rollOverError },
    { isError: updateIsError, message: updateError },
    { isError: deleteIsError, message: deleteError },
    { isError: archiveIsError, message: archiveError },
  ];

  const cancelButton = () => {
    setEditMode(false);
    setMonthName("");
    setYear("");
  };

  const archiveMonthListHandler = (e) => {
    e.preventDefault();
    navigate(`/archive-month/`);
  };

  const rollOverPageHandler = (e) => {
    e.preventDefault();
    navigate(`/rollover/`);
  };

  const handleRollOver = (monthId) => {
    // Handle the rollover logic here
    // Perform your rollover operation
    // Example: dispatch your rollover action here
    dispatch(fetchRollBack(selectedMonthId, monthId));
    setShowModal(false); // Close the modal after handling rollover
    setShouldReload((prev) => !prev); // Reload the list
  };

  const handleModalOpen = (id) => {
    setSelectedMonthId(id);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedMonthId(null);
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchMonth);
  }, [navigate, userInfo, dispatch, shouldReload]);

  return (
    <Container>
      {(loading ||
        addLoading ||
        updateLoading ||
        deleteLoading ||
        archiveLoading ||
        rollOverLoading) && <Loading />}
      {errorConditions?.map(
        (condition, index) =>
          condition.isError && <Error key={index} message={condition.message} />
      )}
      {success && <Success message="Month Created Successfully" />}
      {updateSuccess && <Success message="Month Updated Successfully" />}
      <Row className="justify-content-md-center">
        <Col>
          <Form
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "20px 0px",
              alignItems: "center",
            }}
            onSubmit={editMode ? updateMonthHandler : createMonthHandler}
          >
            <Form.Group controlId="monthName">
              <Form.Control
                style={{ width: "400px" }}
                type="text"
                placeholder="Enter Month Name"
                value={monthName}
                onChange={(e) => setMonthName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="year">
              <Form.Control
                style={{ width: "400px" }}
                type="text"
                placeholder="Enter Year"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant={editMode ? "success" : "primary"} type="submit">
              {editMode ? <>Update Month</> : <>Create Month</>}
            </Button>
            {editMode && (
              <Button variant="danger" onClick={cancelButton}>
                Cancel
              </Button>
            )}
          </Form>
        </Col>
      </Row>

      <Row className="d-flex justify-content-between align-items-center">
        <Col
          xs={12}
          md={6}
          style={{
            margin: "20px 0",
          }}
        >
          <h1>Month List</h1>
        </Col>

        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-end"
          style={{
            margin: "20px 0",
          }}
        >
          <div
            style={{
              width: "150px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Image
              src={rolloverImg}
              alt="rollover"
              title="Roll Over"
              style={{
                width: "35px",
                height: "35px",
                cursor: "pointer",
              }}
              onClick={rollOverPageHandler}
            />
            <Image
              src={archiveImg}
              alt="archive"
              title="Archive List"
              style={{
                width: "35px",
                height: "35px",
                cursor: "pointer",
              }}
              onClick={archiveMonthListHandler}
            />
          </div>
        </Col>
      </Row>

      <Table striped bordered hover style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Month Name</th>
            <th>Year</th>
            <th>Action</th>
          </tr>
        </thead>
        {months?.length > 0 ? (
          <tbody>
            {Array.isArray(months) &&
              months?.map((month, index) => (
                <tr key={month.id}>
                  <td>{index + 1}</td>
                  <td>{month.month_name}</td>
                  <td>{month.year}</td>
                  <td
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Image
                      src={editButton}
                      alt="edit"
                      title="Edit"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        editButtonHandeler(
                          month.id,
                          month.month_name,
                          month.year
                        )
                      }
                    />
                    <Image
                      src={deleteButton}
                      alt="delete"
                      title="Delete"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => deleteButtonHandler(month.id)}
                    />
                    <Image
                      src={archiveButton}
                      alt="archive"
                      title="Archive"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => archiveButtonHandeler(month.id)}
                    />
                    <Image
                      src={rollBackButton}
                      alt="roll over"
                      title="Roll Over"
                      style={{
                        width: "25px",
                        height: "25px",
                        padding: "3px",
                        borderRadius: "2px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleModalOpen(month.id)} // Open the modal on click
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan="4">No Months Available</td>
            </tr>
          </tbody>
        )}
      </Table>

      {/* Modal for Month Roll Over */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Month to Roll Over</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {months?.map((month) => (
              <ListGroup.Item
                key={month.id}
                action
                onClick={() => handleRollOver(month.id)}
              >
                {month.month_name} - {month.year}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
