import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import NotFound from "./components/ui/NotFound";
import Home from "./components/Pages/Home";
import Product from "./components/Pages/Product";
import CreatePulletInventory from "./components/Pages/CreatePulletInventory";
import DetailsPulletInventory from "./components/Pages/DetailsPulletInventory";
import ManageShipDate from "./components/Pages/ManageShipDate";
import Order from "./components/Pages/Order";
import Report from "./components/Pages/Report";
import Slip from "./components/Pages/Slip";
import OrderDetails from "./components/Pages/OrderDetails";
import MonthManage from "./components/Pages/MonthManage";
import Chart from "./components/Pages/Chart";
import Avaibility from "./components/Pages/Avaibility";
import ArchiveMonthList from "./components/Pages/ArchiveMonthList";
import MonthPulletDetails from "./components/Pages/MonthPulletDetails";
import RollOverPage from "./components/Pages/RollOverPage";
import VendorList from "./components/Pages/VendorList";
import VendorDetails from "./components/Pages/VendorDetails";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/availability" element={<Avaibility />} />
        <Route path="/product" element={<Product />} />
        <Route path="/chart" element={<Chart />} />
        <Route path="/rollover" element={<RollOverPage />} />
        <Route
          path="/pullet_inventory/:productId"
          element={<DetailsPulletInventory />}
        />
        <Route
          path="/month-pullet/:month/:monthId"
          element={<MonthPulletDetails />}
        />
        <Route path="/order/:orderId" element={<OrderDetails />} />
        <Route path="/shipdate" element={<ManageShipDate />} />
        <Route path="/archive-month" element={<ArchiveMonthList />} />
        <Route path="/order" element={<Order />} />
        <Route path="/report" element={<Report />} />
        <Route path="/packing_slip" element={<Slip />} />
        <Route path="/pullet_inventory" element={<CreatePulletInventory />} />
        <Route path="/month_manage" element={<MonthManage />} />
        <Route path="/vendors" element={<VendorList />} />
        <Route path="/vendor/:vendorId" element={<VendorDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
