import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function NoteModal({ show, handleClose, note, product }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Note for {product}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{note}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
