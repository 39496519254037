import React, { useEffect, useState } from "react";
import backbutton from "../../assets/leftdown.png";
import {
  Col,
  Container,
  Image,
  Row,
  Table,
  Button,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../ui/Loading";
import Error from "../ui/Error";
import fetchVendorView from "../../redux/thunk/fetchVendorView";
import fetchVendorUpdate from "../../redux/thunk/fetchUpdateVendor";

export default function VendorDetails() {
  const { vendorId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = useSelector((state) => state.login);
  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { userInfo } = login || {};
  const { isError, loading, vendor, error } = vendorDetails || {};

  const [isEditing, setIsEditing] = useState(false);
  const [editVendor, setEditVendor] = useState({});
  const [photo, setPhoto] = useState(null);

  const styles = {
    center: { textAlign: "center", margin: "20px 0px" },
    imageButton: {
      marginLeft: "30px",
      height: "30px",
      width: "30px",
      cursor: "pointer",
    },
    logo: { height: "100px", width: "100px", marginBottom: "20px" },
  };

  const inputFields = [
    { label: "Company Name", name: "company_name", type: "text" },
    { label: "Street Address", name: "street_address", type: "text" },
    { label: "City", name: "city", type: "text" },
    { label: "State", name: "state", type: "text" },
    { label: "Zip Code", name: "zip_code", type: "text" },
    { label: "Phone Number", name: "phone_number", type: "text" },
  ];

  useEffect(() => {
    if (!userInfo) navigate("/");
    dispatch(fetchVendorView(vendorId));
  }, [dispatch, navigate, userInfo, vendorId]);

  useEffect(() => {
    if (vendor) {
      setEditVendor((prevState) => ({
        ...prevState,
        ...vendor,
      }));
    }
  }, [vendor]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditVendor((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhotoChange = (e) => setPhoto(e.target.files[0]);

  const handleEditClick = () => setIsEditing(true);

  const handleSaveClick = async () => {
    const formData = new FormData();
    if (photo) {
      formData.append("logo", photo); // Add new logo file
    }

    // Add other fields to the form data
    inputFields.forEach(({ name }) => {
      formData.append(name, editVendor[name] || "");
    });

    try {
      await dispatch(fetchVendorUpdate(vendorId, formData));
      await dispatch(fetchVendorView(vendorId)); // Refresh vendor details
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating vendor:", error);
      alert("Failed to update vendor. Please try again.");
    }
  };

  const handleCancelClick = () => {
    setEditVendor(vendor);
    setPhoto(null);
    setIsEditing(false);
  };

  const backButtonHandle = (e) => {
    e.preventDefault();
    navigate(`/vendors/`);
  };

  return (
    <Container>
      {loading && <Loading />}
      {isError && <Error message={error} />}
      <Row className="justify-content-md-center">
        <Col xs={12} md={12} sm={12} style={styles.center}>
          <h1>Vendor Details</h1>
          <Image
            src={backbutton}
            alt="back"
            style={styles.imageButton}
            onClick={backButtonHandle}
          />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs={12} md={8} sm={12} style={styles.center}>
          {isEditing ? (
            <Form>
              <Form.Group>
                <Form.Label>Update Vendor Logo</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handlePhotoChange}
                  accept="image/*"
                />
              </Form.Group>
              {inputFields.map(({ label, name, type }) => (
                <Form.Group key={name}>
                  <Form.Label>{label}</Form.Label>
                  <Form.Control
                    type={type}
                    name={name}
                    value={editVendor[name] || ""}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              ))}
            </Form>
          ) : (
            <>
              <Image
                src={vendor?.logo}
                alt="Vendor Logo"
                style={styles.logo}
                roundedCircle
              />
              <h3>{vendor?.company_name}</h3>
              <p>{vendor?.street_address}</p>
              <p>
                {vendor?.city}, {vendor?.state}, {vendor?.zip_code}
              </p>
              <p>{vendor?.phone_number}</p>
            </>
          )}
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col
          xs={12}
          md={8}
          sm={12}
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          {isEditing ? (
            <>
              <Button variant="success" onClick={handleSaveClick}>
                Save
              </Button>
              <Button
                variant="secondary"
                onClick={handleCancelClick}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button variant="primary" onClick={handleEditClick}>
              Edit
            </Button>
          )}
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs={12} md={12} sm={12} style={{ marginTop: "30px" }}>
          <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
            Vendor Order List
          </h3>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Order Number</th>
                <th>Customer Name</th>
                <th>Shipping Address</th>
                <th>Phone Number</th>
                <th>Shipping Date</th>
              </tr>
            </thead>
            <tbody>
              {vendor?.orders?.length > 0 ? (
                vendor.orders.map((order, index) => (
                  <tr key={order.id}>
                    <td>{index + 1}</td>
                    <td>{order.order_number}</td>
                    <td>{order.customer_name}</td>
                    <td>{order.shipping_address}</td>
                    <td>{order.phone_number}</td>
                    <td>{order.shipping_date}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    No Orders Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
