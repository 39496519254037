import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backbutton from "../../assets/leftdown.png";
import Error from "../ui/Error";
import Loading from "../ui/Loading";
import RolloverIllustration from "./RolloverIllustration";
import fetchRolloverData from "../../redux/thunk/fetchRolloverData";

export default function RollOverPage() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const monthRolloverData = useSelector((state) => state.monthRolloverData);
  const { userInfo } = login || {};
  const { months, loading, isError, error } = monthRolloverData || {};
  const backArchiveListHandler = (e) => {
    e.preventDefault();
    navigate(`/month_manage/`);
  };
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
    dispatch(fetchRolloverData);
  }, [navigate, userInfo, dispatch]);
  return (
    <Container>
      {loading && <Loading />}
      {isError && <Error message={error} />}
      <Row className="d-flex justify-content-between align-items-center">
        <Col
          xs={6}
          md={6}
          style={{
            margin: "20px 0",
          }}
        >
          <h1>Archive Rollover Data</h1>
        </Col>
        <Col
          xs={6}
          md={6}
          className="d-flex justify-content-end"
          style={{
            margin: "20px 0",
          }}
        >
          <Image
            src={backbutton}
            alt="archive"
            title="Archive List"
            style={{
              width: "35px",
              height: "35px",
              cursor: "pointer",
            }}
            onClick={backArchiveListHandler}
          />
        </Col>
      </Row>
      <Row>
        {months?.length > 0 ? (
          <div>
            {Array.isArray(months) &&
              months?.map((month, index) => (
                <RolloverIllustration
                  key={index}
                  data={month}
                  refetchData={() => dispatch(fetchRolloverData)}
                />
              ))}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            No Data Found
          </div>
        )}
      </Row>
    </Container>
  );
}
