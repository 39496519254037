import { vendorList } from "../vendor/actions";
import { VENDOR_LIST_FAIL, VENDOR_LIST_REQUEST } from "../vendor/actionType";
import axios from "./utils/axios";

const fetchVendorList = async (dispatch, getState) => {
  try {
    dispatch({ type: VENDOR_LIST_REQUEST });
    const {
      login: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`api/vendor/`, config);
    dispatch(vendorList(data));
  } catch (error) {
    dispatch({
      type: VENDOR_LIST_FAIL,
      payload:
        error.response && error.response.data.details
          ? error.response.data.details
          : error.message,
    });
  }
};
export default fetchVendorList;
