export const CREATE_VENDOR_REQUEST = "vendor/CREATE_VENDOR_REQUEST";
export const CREATE_VENDOR_SUCCESS = "vendor/CREATE_VENDOR_SUCCESS";
export const CREATE_VENDOR_FAIL = "vendor/CREATE_VENDOR_FAIL";

export const UPDATE_VENDOR_REQUEST = "vendor/UPDATE_VENDOR_REQUEST";
export const UPDATE_VENDOR_SUCCESS = "vendor/UPDATE_VENDOR_SUCCESS";
export const UPDATE_VENDOR_FAIL = "vendor/UPDATE_VENDOR_FAIL";

export const VENDOR_DETAILS_REQUEST = "vendor/VENDOR_DETAILS_REQUEST";
export const VENDOR_DETAILS_SUCCESS = "vendor/VENDOR_DETAILS_SUCCESS";
export const VENDOR_DETAILS_FAIL = "vendor/VENDOR_DETAILS_FAIL";

export const VENDOR_LIST_REQUEST = "vendor/VENDOR_LIST_REQUEST";
export const VENDOR_LIST_SUCCESS = "vendor/VENDOR_LIST_SUCCESS";
export const VENDOR_LIST_FAIL = "vendor/VENDOR_LIST_FAIL";

export const VENDOR_DELETE_REQUEST = "vendor/VENDOR_DELETE_REQUEST";
export const VENDOR_DELETE_SUCCESS = "vendor/VENDOR_DELETE_SUCCESS";
export const VENDOR_DELETE_FAIL = "vendor/VENDOR_DELETE_FAIL";
