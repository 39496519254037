import React from "react";
import FaArrowRight from "../../assets/right_arrow.png"; // Make sure this path is correct
import { useDispatch, useSelector } from "react-redux";
import fetchUndoRolloverData from "../../redux/thunk/fetchUndoArchiveMonthRolloverData";
import Loading from "../ui/Loading";
import Error from "../ui/Error";

const RolloverIllustration = ({ data, refetchData }) => {
  const dispatch = useDispatch();
  const undoMonthRolloverData = useSelector(
    (state) => state.undoMonthRolloverData
  );
  const { loading, isError, error } = undoMonthRolloverData || {};

  const undoChange = (id) => {
    // Dispatch the undo action and refetch the rollover data
    dispatch(fetchUndoRolloverData(id)).then(() => {
      refetchData(); // Re-fetch the data after undo is completed
    });
  };

  return (
    <div
      className="container mt-4"
      style={{ border: "1px solid black", padding: "15px", cursor: "pointer" }}
    >
      {loading && <Loading />}
      {isError && <Error message={error} />}
      <div className="row align-items-center">
        <div className="col text-center">
          <h5>Rollover Month:</h5>
          <p className="font-weight-bold">{data?.rollover_month}</p>
        </div>
        <div className="col text-center">
          <img
            src={FaArrowRight}
            alt="Arrow Right"
            style={{ width: "30px" }}
            className="text-primary"
          />
          <h5>Rollover Data:</h5>
          <div className="d-flex justify-content-center flex-wrap">
            {data?.rollover_data.map((item, index) => (
              <div
                key={index + 1}
                className="p-3 border rounded m-1 bg-light text-center shadow-sm"
                style={{ minWidth: "100px" }}
              >
                <span className="font-weight-bold">{item.product}:</span>{" "}
                {item.gross_available}
              </div>
            ))}
          </div>
        </div>
        <div className="col text-center">
          <h5>Receive Month:</h5>
          <p className="font-weight-bold">{data?.current_month}</p>
        </div>
      </div>
      <div className="text-center mt-3">
        <button
          className="btn btn-warning"
          onClick={() => undoChange(data?.id)}
        >
          Undo
        </button>
      </div>
    </div>
  );
};

export default RolloverIllustration;
