import { monthRookBack } from "../user/actions"; // Fixed typo
import {
  ROLLBACK_MONTH_FAIL,
  ROLLBACK_MONTH_REQUEST,
} from "../user/actionType";
import axios from "./utils/axios";

const fetchRollBack = (selectedMonthId, monthId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ROLLBACK_MONTH_REQUEST });

      const {
        login: { userInfo },
      } = getState();

      if (!userInfo?.token) {
        throw new Error("User is not authenticated.");
      }

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `api/common/month/rollback/${monthId}/`,
        { current_month_id: selectedMonthId },
        config
      );

      dispatch(monthRookBack(data)); // Fixed action name
    } catch (error) {
      dispatch({
        type: ROLLBACK_MONTH_FAIL,
        payload:
          error.response && error.response.data && error.response.data.details
            ? error.response.data.details
            : error.message,
      });
    }
  };
};

export default fetchRollBack;
