import {
  ALL_MONTH_GET_FAIL,
  ALL_MONTH_GET_REQUEST,
  ALL_MONTH_GET_SUCCESS,
  ARCHIVE_LIST_MONTH_FAIL,
  ARCHIVE_LIST_MONTH_REQUEST,
  ARCHIVE_LIST_MONTH_SUCCESS,
  ARCHIVE_MONTH_FAIL,
  ARCHIVE_MONTH_REQUEST,
  ARCHIVE_MONTH_ROLLOVER_DATA_FAIL,
  ARCHIVE_MONTH_ROLLOVER_DATA_REQUEST,
  ARCHIVE_MONTH_ROLLOVER_DATA_SUCCESS,
  ARCHIVE_MONTH_SUCCESS,
  MONTH_CREAETE_FAIL,
  MONTH_CREAETE_REQUEST,
  MONTH_CREAETE_SUCCESS,
  MONTH_DELETE_FAIL,
  MONTH_DELETE_REQUEST,
  MONTH_DELETE_SUCCESS,
  MONTH_UPDATE_FAIL,
  MONTH_UPDATE_REQUEST,
  MONTH_UPDATE_SUCCESS,
  ROLLBACK_MONTH_FAIL,
  ROLLBACK_MONTH_REQUEST,
  ROLLBACK_MONTH_SUCCESS,
  UNDO_ARCHIVE_MONTH_FAIL,
  UNDO_ARCHIVE_MONTH_REQUEST,
  UNDO_ARCHIVE_MONTH_ROLLOVER_DATA_FAIL,
  UNDO_ARCHIVE_MONTH_ROLLOVER_DATA_REQUEST,
  UNDO_ARCHIVE_MONTH_ROLLOVER_DATA_SUCCESS,
  UNDO_ARCHIVE_MONTH_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "./actionType";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
        isError: false,
      };

    case USER_LOGIN_FAIL:
      return {
        loading: false,
        userInfo: "",
        isError: true,
        error: action.payload,
      };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const allMonthReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_MONTH_GET_REQUEST:
      return {
        loading: true,
      };
    case ALL_MONTH_GET_SUCCESS:
      return {
        loading: false,
        months: action.payload,
        isError: false,
        success: true,
      };

    case ALL_MONTH_GET_FAIL:
      return {
        loading: false,
        months: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const monthCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MONTH_CREAETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MONTH_CREAETE_SUCCESS:
      return {
        loading: false,
        month: action.payload,
        success: true,
        isError: false,
      };
    case MONTH_CREAETE_FAIL:
      return {
        loading: false,
        month: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const monthUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MONTH_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MONTH_UPDATE_SUCCESS:
      return {
        loading: false,
        month: action.payload,
        success: true,
        isError: false,
      };
    case MONTH_UPDATE_FAIL:
      return {
        loading: false,
        month: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const monthDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MONTH_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MONTH_DELETE_SUCCESS:
      return {
        loading: false,
        month: action.payload,
        success: true,
        isError: false,
      };
    case MONTH_DELETE_FAIL:
      return {
        loading: false,
        month: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const monthRollBackReducer = (state = {}, action) => {
  switch (action.type) {
    case ROLLBACK_MONTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ROLLBACK_MONTH_SUCCESS:
      return {
        loading: false,
        month: action.payload,
        success: true,
        isError: false,
      };
    case ROLLBACK_MONTH_FAIL:
      return {
        loading: false,
        month: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const monthArchiveReducer = (state = {}, action) => {
  switch (action.type) {
    case ARCHIVE_MONTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ARCHIVE_MONTH_SUCCESS:
      return {
        loading: false,
        month: action.payload,
        success: true,
        isError: false,
      };
    case ARCHIVE_MONTH_FAIL:
      return {
        loading: false,
        month: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const undoMonthArchiveReducer = (state = {}, action) => {
  switch (action.type) {
    case UNDO_ARCHIVE_MONTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UNDO_ARCHIVE_MONTH_SUCCESS:
      return {
        loading: false,
        month: action.payload,
        success: true,
        isError: false,
      };
    case UNDO_ARCHIVE_MONTH_FAIL:
      return {
        loading: false,
        month: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const archiveMonthListReducer = (state = [], action) => {
  switch (action.type) {
    case ARCHIVE_LIST_MONTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ARCHIVE_LIST_MONTH_SUCCESS:
      return {
        loading: false,
        months: action.payload,
        success: true,
        isError: false,
      };
    case ARCHIVE_LIST_MONTH_FAIL:
      return {
        loading: false,
        months: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const archiveMonthRolloverDataReducer = (state = [], action) => {
  switch (action.type) {
    case ARCHIVE_MONTH_ROLLOVER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ARCHIVE_MONTH_ROLLOVER_DATA_SUCCESS:
      return {
        loading: false,
        months: action.payload,
        success: true,
        isError: false,
      };
    case ARCHIVE_MONTH_ROLLOVER_DATA_FAIL:
      return {
        loading: false,
        months: [],
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const undoArchiveMonthRolloverDataReducer = (state = {}, action) => {
  switch (action.type) {
    case UNDO_ARCHIVE_MONTH_ROLLOVER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UNDO_ARCHIVE_MONTH_ROLLOVER_DATA_SUCCESS:
      return {
        loading: false,
        month: action.payload,
        success: true,
        isError: false,
      };
    case UNDO_ARCHIVE_MONTH_ROLLOVER_DATA_FAIL:
      return {
        loading: false,
        month: {},
        isError: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
