import { combineReducers } from "redux";
import {
  allMonthReducer,
  archiveMonthListReducer,
  archiveMonthRolloverDataReducer,
  monthArchiveReducer,
  monthCreateReducer,
  monthDeleteReducer,
  monthRollBackReducer,
  monthUpdateReducer,
  undoArchiveMonthRolloverDataReducer,
  undoMonthArchiveReducer,
  userLoginReducer,
} from "./user/userReducer";
import {
  productAddReducer,
  productDeleteReducer,
  productListReducer,
  productUpdateReducer,
  productViewReducer,
} from "./product/productReducer";
import {
  monthlyPulletDetailsReducer,
  pulletInventoryCreateReducer,
  pulletInventoryDeleteReducer,
  pulletInventoryMonthlyCreateReducer,
  pulletInventoryUpdateReducer,
  pulletListReducer,
} from "./pullet/pulletInventoryReducer";
import {
  orderCancelReducer,
  orderCreateReducer,
  orderDeleteReducer,
  orderListReducer,
  orderReportReducer,
  orderShipmentAvailableDateReducer,
  orderStatusChangeReducer,
  orderSummaryReducer,
  orderUpdateReducer,
  orderViewReducer,
  shipmentAvaibilityReducer,
  shipmentCreateReducer,
  shipmentDateListReducer,
  shipmentDateReportReducer,
  shipmentDeleteReducer,
  shipmentReportReducer,
  shipmentUpdateReducer,
} from "./order/orderReducer";
import {
  vendorCreateReducer,
  vendorDeleteReducer,
  vendorListReducer,
  vendorUpdateReducer,
  vendorViewReducer,
} from "./vendor/vendorReducer";
const rootReducer = combineReducers({
  login: userLoginReducer,
  monthList: allMonthReducer,
  monthAdd: monthCreateReducer,
  monthUpdate: monthUpdateReducer,
  monthDelete: monthDeleteReducer,
  monthRollBack: monthRollBackReducer,
  monthRolloverData: archiveMonthRolloverDataReducer,
  undoMonthRolloverData: undoArchiveMonthRolloverDataReducer,
  archiveMonth: monthArchiveReducer,
  undoArchiveMonth: undoMonthArchiveReducer,
  listArchiveMonth: archiveMonthListReducer,
  addProduct: productAddReducer,
  viewProduct: productViewReducer,
  updateProduct: productUpdateReducer,
  deleteProduct: productDeleteReducer,
  productList: productListReducer,
  pulletList: pulletListReducer,
  monthlyPulletDetails: monthlyPulletDetailsReducer,
  createPulletInventory: pulletInventoryCreateReducer,
  createMonthlyPulletInventory: pulletInventoryMonthlyCreateReducer,
  updatePulletInventory: pulletInventoryUpdateReducer,
  deletePulletInventory: pulletInventoryDeleteReducer,
  shipmentDateList: shipmentDateListReducer,
  createShipment: shipmentCreateReducer,
  updateShipment: shipmentUpdateReducer,
  deleteShipment: shipmentDeleteReducer,
  orderlist: orderListReducer,
  orderAvailableDate: orderShipmentAvailableDateReducer,
  orderCreate: orderCreateReducer,
  orderUpdate: orderUpdateReducer,
  orderDelete: orderDeleteReducer,
  orderView: orderViewReducer,
  orderCancel: orderCancelReducer,
  orderStatusChange: orderStatusChangeReducer,
  orderReport: orderReportReducer,
  orderSummary: orderSummaryReducer,
  shipmentReport: shipmentDateReportReducer,
  shipmentCoverReport: shipmentReportReducer,
  shipmentAvaibilility: shipmentAvaibilityReducer,
  vendorList: vendorListReducer,
  vendorDetails: vendorViewReducer,
  vendorUpdate: vendorUpdateReducer,
  createVendor: vendorCreateReducer,
  deleteVendor: vendorDeleteReducer,
});

export default rootReducer;
